export const homeIcon = () => 'i-heroicons-home'
export const planeIcon = () => 'i-bi-airplane-engines'
export const contentIcon = () => 'i-ic:round-content-paste-search'
export const loadingIcon = () => 'i-mingcute-location-fill'
export const exchangeIcon = () => 'i-humbleicons-exchange-horizontal'
export const chevronDownIcon = () => 'i-heroicons-chevron-down-20-solid'
export const chevronLeftIcon = () => 'i-heroicons-chevron-left-20-solid'
export const chevronRightIcon = () => 'i-heroicons-chevron-right-20-solid'
export const chevronUpIcon = () => 'i-heroicons-chevron-up-20-solid'
export const searchIcon = () => 'i-mingcute-search-line'
export const longArrowRight = () => 'i-mynaui-arrow-long-right'
export const longArrowLeft = () => 'i-mynaui-arrow-long-left'
export const fillLocationIcon = () => 'i-mingcute-location-fill'
export const hotelIcon = () => 'i-lucide-hotel'
export const tourIcon = () => 'i-material-symbols-light-flights-and-hotels-outline-rounded'
export const trainIcon = () => 'i-lucide-train-front'
export const busIcon = () => 'i-mingcute-bus-2-line'
export const instagramIcon = () => 'lineicons:instagram'
export const xIcon = () => 'proicons:x-twitter'
export const plusIcon = () => 'i-material-symbols-add'
export const minusIcon = () => 'i-material-symbols-remove'
export const sunriseIcon = () => 'i-mingcute-sun-fog-line'
export const sunlightIcon = () => 'i-mingcute-sun-line'
export const sunsetIcon = () => 'i-mingcute-sun-fog-fill'
export const moonIcon = () => 'i-mingcute-moon-stars-fill'
export const calendarIcon = () => 'i-material-symbols-event'
export const infoIcon = () => 'i-heroicons-information-circle'
export const airportIcon = () => 'mdi:airport'
export const buildingAirportIcon = () => 'tabler:building-airport'
export const connectingAirportIcon = () => 'ic:twotone-connecting-airports'
